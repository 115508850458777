const initialState = {
    backgroundColor: "",
    textColor: "",
    text: "",
    fontFamily: "",
    borderRadius: "",
    borderWidth: "",
    borderColor: "",
    image:""
  };
  
  export default function (state = initialState, action) {
    switch (action.type) {
      case "ADD_BG_COLOR":
        return {
          ...state,
          backgroundColor: action.payload,
        };
      case "ADD_TEXT_COLOR":
        return {
          ...state,
          textColor: action.payload,
        };
      case "ADD_TEXT":
        return {
          ...state,
          text: action.payload,
        };
      case "ADD_FONT_FAMILY":
        return {
          ...state,
          fontFamily: action.payload,
        };
      case "ADD_BORDER_RADIUS":
        return {
          ...state,
          borderRadius: action.payload,
        };
      case "ADD_BORDER_WIDTH":
        return {
          ...state,
          borderWidth: action.payload,
        };
      case "ADD_BORDER_COLOR":
        return {
          ...state,
          borderColor: action.payload,
        };
      case "SET_IMAGE":
        return {
        ...state,
        image: action.payload,
       };  
  
      default:
        return state;
    }
  }
  
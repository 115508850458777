import React, { useState, useEffect } from "react";
import Navbar from "../Navbar";
import Footer from "../Footer";
import swal from "sweetalert";
import { FiTrash2 } from "react-icons/fi";
import { setAlert } from "../../actions/alert";
import { baseURL } from "../../global/global";
import TitleBar from "../TitleBar";
import { SketchPicker } from "react-color";
import { FiUploadCloud } from "react-icons/fi";
import dummyProduct from "../../images/dummyProduct.jpg";
import "./ButtonCode.css";
import WebFont from "webfontloader";
import queryString from "query-string";
import { useDispatch, useSelector } from "react-redux";
import SweetAlert from "react-bootstrap-sweetalert";
import {
  addBgColor,
  addTextColor,
  addText,
  addBorderColor,
  addBorderRadius,
  addBorderWidth,
  setImage,
  //setImage,
} from "../../actions/buttonCodeAction";

import axios from "axios";
import { Placeholder } from "react-bootstrap";
import Alert from "../Alert";

const ButtonCode = ({ location }) => {
  const productName = queryString.parse(location.search).productName;
  const productId = queryString.parse(location.search).productId;
  const productImg = queryString.parse(location.search).image;
  //console.log(productName,"vivek")

  const buttonData = useSelector((state) => state.button);
  const dispatch = useDispatch();

  const auth = useSelector((state) => state.auth);

  const [font, setFont] = useState([]);
  const [selectedFont, setselectedFont] = useState("Roboto");
  const [button, setButton] = useState("Delete");
  const [showDelete, setShowDelete] = useState(false);

  const [open, setOpen] = useState(false);
  const [open1, setOpen1] = useState(false);
  const [open2, setOpen2] = useState(false);
  const [imageId, setImageId] = useState(-1);
  const [inbuiltBtn, setInBuiltBtn] = useState([]);
  const [uploadImage, setUplaodImage] = useState();
  const [toggle, setToggle] = useState({
    inBuiltButton: true,
    customButton: false,
    fetchButton: false,
  });
  const jsonData = {
    text: buttonData.text,
    bgColor: buttonData.backgroundColor,
    textColor: buttonData.textColor,
    borderColor: buttonData.borderColor,
    borderRadius: buttonData.borderRadius,
    borderWidth: buttonData.borderWidth,
    fontFamily: selectedFont,
  };
  //console.log(jsonData,"asdfgh")
  const [loader, setLoader] = useState({
    pImageLoader: false,
    pImageName: "",
  });

  const [btnData, setBtnData] = useState(false);

  const [btnImage, setBtnImage] = useState([]);

  const openBox = () => {
    setOpen(true);
  };
  const openBox1 = () => {
    setOpen1(true);
  };

  const openBox2 = () => {
    setOpen2(true);
    window.scrollTo(0, 800);
  };
  const handleBorderRadius = (e) => {
    //setBtnBorderRadius(e.target.value)
    dispatch(addBorderRadius(e.target.value));
  };
  const handleBorderWidth = (e) => {
    //setBtnBorderWidth(e.target.value)
    dispatch(addBorderWidth(e.target.value));
  };
  const closeBackGroundColor = () => {
    setOpen(false);
  };
  const closeTextColor = () => {
    setOpen1(false);
  };
  const closeBorderColor = (e) => {
    setOpen2(false);
  };

  const handleTextArea = (e) => {
    // setText(e.target.value)
    dispatch(addText(e.target.value));
  };

  const handleFont = (e) => {
    setselectedFont(e.target.value);
  };

  const handleToggle = (e) => {
    const { name, checked } = e.target;
    if (name === "customBtn") {
      setToggle({
        ...toggle,
        customButton: checked,
        inBuiltButton: false,
        fetchButton: false,
      });
    } else if (name === "InbuiltBtn") {
      setToggle({
        ...toggle,
        inBuiltButton: checked,
        customButton: false,
        fetchButton: false,
      });
    } else if (name === "uploadBtn") {
      setToggle({
        ...toggle,
        fetchButton: checked,
        customButton: false,
        inBuiltButton: false,
      });
    }
  };

  //Inbuilt button Call

  const fetchBtn = () => {
    setLoader({ ...loader, pImageLoader: true });

    axios({
      method: "POST",
      url: `${baseURL}fetch-buttons`,
      headers: {
        "content-type": "application/json",
        Authorization: auth.token,
      },
    })
      .then((res) => {
        setInBuiltBtn(res.data.data);
        setLoader({
          ...loader,
          pImageLoader: false,
        });
      })
      .catch((error) => {
        console.log("error");
        setLoader({
          ...loader,
          pImageLoader: false,
        });
      });
  };

  useEffect(() => {
    fetchBtn();
  }, []);

  //Font loader

  useEffect(() => {
    axios
      .get("https://adminserver.reelapps.io/fetch-fonts")
      .then((res) => {
        const FontChoose = res.data.data;
        let arr = [];
        FontChoose.map((f) => {
          arr.push(f.value);
        });
        setFont(arr);
      })
      .catch((error) => {
        console.log(error);
      });
  }, []);

  useEffect(() => {
    if (font.length > 0) {
      WebFont.load({
        google: {
          families: font,
        },
      });
    }
  }, [font]);

  //Fetch user button

  let allowedExt = ["image/png", "image/jpg", "image/jpeg"];

  const onUploadBtn = (e) => {
    setLoader({ ...loader, pImageLoader: true });

    if (e.target.files[0] !== undefined) {
      if (allowedExt.includes(e.target.files[0].type)) {
        let allowedSize = 100000000;

        if (e.target.files[0].size < allowedSize) {
          const formData = new FormData();
          formData.append("type", "buttonImage");
          for (let index = 0; index < e.target.files.length; index++) {
            const element = e.target.files[index];
            formData.append("file[]", element);
          }

          axios({
            method: "POST",
            url: `${baseURL}upload-multi-user-media`,
            data: formData,
            headers: {
              "Content-Type": "application/json",
              Authorization: auth.token,
            },
          })
            .then((res) => {
              if (res.data.status === true) {
                fetchImage();
              } else {
                dispatch(setAlert(res.data.message, "danger"));
              }
              setLoader({
                ...loader,
                pImageLoader: false,
              });
            })
            .catch((error) => {
              console.log(error);
              setLoader({
                ...loader,
                pImageLoader: false,
              });
            });
        } else {
          swal("Oops!", "Max allowed size for image 100MB");
        }
      } else {
        swal("Oops!", "You have Selected Invalid File Type");
      }
    }
  };
  const fetchImage = () => {
    axios({
      method: "POST",
      url: `${baseURL}fetch-user-uploaded-file`,
      headers: {
        "content-type": "application/json",
        Authorization: auth.token,
      },
      data: { type: "buttonImage" },
    })
      .then((res) => {
        if (res.data.status === true) {
          setBtnImage(res.data.data);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  useEffect(() => {
    fetchImage();
  }, []);

  //Delete User button

  const handleDelete = (id) => {
    setShowDelete(true);
    setImageId(id);
  };

  const onConfirm = () => {
    setButton("Deleting...");

    const formData = new FormData();
    formData.append("id", imageId);

    axios({
      method: "POST",
      url: `${baseURL}delete-media-file`,
      data: formData,
      headers: {
        "content-type": "application/json",
        Authorization: auth.token,
      },
    })
      .then((res) => {
        if (res.data.status === true) {
          dispatch(setAlert(res.data.message, "success"));
          fetchImage();
        } else {
          dispatch(setAlert(res.data.message, "danger"));
        }
        setButton("Delete");
        setShowDelete(false);
      })
      .catch((error) => {
        console.log("error");
      });
  };
  const onCancel = () => {
    setShowDelete(false);
  };

  //save button to backend
  const selectUploadBtn = (obj) => {
    let data = {
      button_type: "upload",
      product_id: productId,
      button_id: 0,
      button_url: obj.url,
      data: "null",
    };
    setBtnData(data);
  };

  const selectInbuiltBtn = (obj) => {
    let data = {
      button_type: "inbuilt",
      product_id: productId,
      button_id: obj.id,
      button_url: obj.url,
      data: "null",
    };
    setBtnData(data);
  };

  const generateBtn = () => {
    let obj = {
      button_type: "custom",
      product_id: productId,
      button_id: 0,
      data: JSON.stringify(jsonData),
    };

    axios({
      method: "POST",
      url: `${baseURL}product-button-genrate`,
      data: toggle.customButton ? obj : btnData,
      headers: {
        "content-type": "application/json",
        Authorization: auth.token,
      },
    })
      .then((res) => {
        if (res.data.status === true) {
          dispatch(setAlert(res.data.message, "success"));
        } else {
          dispatch(setAlert(res.data.message, "danger"));
        }
      })
      .catch((error) => {
        console.log("error");
      });
  };
  //console.log(buttonData,'sdva')

  return (
    <>
      <TitleBar title="Button Code" />
      <Navbar />
      <Alert />
      <section className="siteWrap">
        <div className="pageWrap alt">
          <div className="pageBread forProduct">
            <div className="container">
              <div className="proPic">
                <img src={productImg ? productImg : dummyProduct} alt="" />
              </div>
              <div className="row justify-content-center">
                <div className="col-lg-12">
                  <ul>
                    <li>
                      <a href="">{productName} </a>
                    </li>
                    <li>
                      <a href="">Button Design </a>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
          <div className="container">
            <div className="pageTitle withBtn">
              <h2>Your Button Design</h2>
            </div>
            <div className="productTable forBtn">
              <div
                style={{ display: "flex", borderBottom: "1px solid #a82f98" }}
              >
                <h3>Inbuilt Buttons</h3>
                <div
                  className="switch-single abs"
                  style={{ position: "absolute", right: "140px" }}
                >
                  <label className="switch">
                    <input
                      name="InbuiltBtn"
                      type="checkbox"
                      checked={toggle.inBuiltButton}
                      onChange={(e) => handleToggle(e)}
                    />
                    <span className="slider round"></span>
                  </label>
                </div>
              </div>
              {toggle.inBuiltButton ? (
                <div className="buttonList">
                  <ul>
                    {inbuiltBtn.map((curBtn, index) => {
                      return (
                        <li key={index}>
                          <div
                            className="buttonList customUp cursor-pointer btnHover"
                            style={{ height: "150px" }}
                          >
                            {loader.pImageLoader ? (
                              <i className="fa fa-spinner fa-spin loadBtn " />
                            ) : (
                              <img
                                style={{ width: "100%" }}
                                src={curBtn.url}
                                alt=""
                                onClick={() => selectInbuiltBtn(curBtn)}
                              />
                            )}
                          </div>
                        </li>
                      );
                    })}
                  </ul>
                </div>
              ) : (
                ""
              )}

              <div className="backgrounPicker">
                {open && (
                  <>
                    <div
                      className="colorPopUp "
                      onClick={(e) => closeBackGroundColor(e)}
                    />

                    <SketchPicker
                      className=""
                      color={buttonData.backgroundColor}
                      onChange={(e) => {
                        // setBackGroundColor(e.hex);
                        dispatch(addBgColor(e.hex));
                      }}
                    />
                  </>
                )}
              </div>

              <div className="textPicker">
                {open1 && (
                  <>
                    <div
                      className="colorPopUp"
                      onClick={(e) => closeTextColor(e)}
                    />

                    <SketchPicker
                      color={buttonData.textColor}
                      onChange={(e) => {
                        //setTextPickerColor(e.hex);
                        dispatch(addTextColor(e.hex));
                      }}
                    />
                  </>
                )}
              </div>
              <div className="borderPicker">
                {open2 && (
                  <>
                    <div
                      className="colorPopUp"
                      onClick={(e) => closeBorderColor(e)}
                    />

                    <SketchPicker
                      color={buttonData.borderColor}
                      onChange={(e) => {
                        //setBtnBorderColor(e.hex);
                        dispatch(addBorderColor(e.hex));
                      }}
                    />
                  </>
                )}
              </div>

              <div
                className="mt-5 mb-5"
                style={{ display: "flex", borderBottom: "1px solid #a82f98" }}
              >
                <h3>Button Design</h3>
                <div
                  className="switch-single abs"
                  style={{ position: "absolute", right: "140px" }}
                >
                  <label className="switch">
                    <input
                      name="customBtn"
                      type="checkbox"
                      checked={toggle.customButton}
                      onChange={(e) => handleToggle(e)}
                    />
                    <span className="slider round"></span>
                  </label>
                </div>
              </div>
              {toggle.customButton ? (
                <div className="row">
                  <div className="col-sm-8">
                    <div className="inpWrapN mt-0">
                      <div className="row">
                        <div className="col-lg-4">
                          <label>Background Color:</label>
                        </div>
                        {/* button input */}
                        <div className="col-lg-8">
                          <div style={{ display: "flex" }}>
                            <input
                              className="searchInp cursor-pointer"
                              readOnly
                              type="text"
                              onClick={openBox}
                              placeholder={
                                buttonData.backgroundColor === ""
                                  ? "#FFFFF"
                                  : buttonData.backgroundColor
                              }
                            />

                            <div className="colorBox ">
                              <input
                                className="searchInp cursor-pointer"
                                style={{
                                  backgroundColor: `${buttonData.backgroundColor}`,
                                  width: `${"35px"}`,
                                  borderStyle: `${"solid"}`,
                                }}
                                type="text"
                                onClick={openBox}
                                readOnly
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="inpWrapN">
                      <div className="row">
                        <div className="col-lg-4">
                          <label>Text Color:</label>
                        </div>
                        <div className="col-lg-8">
                          <div style={{ display: "flex" }}>
                            <input
                              className="searchInp cursor-pointer"
                              readOnly
                              type="text"
                              onClick={openBox1}
                              placeholder={
                                buttonData.textColor === ""
                                  ? "#00000"
                                  : buttonData.textColor
                              }
                            />
                            <div className="colorBox">
                              <input
                                className="searchInp cursor-pointer"
                                style={{
                                  backgroundColor: `${buttonData.textColor}`,
                                  width: `${"35px"}`,
                                  borderStyle: `${"solid"}`,
                                }}
                                type="text"
                                onClick={openBox1}
                                readOnly
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="inpWrapN">
                      <div className="row">
                        <div className="col-lg-4">
                          <label>Text:</label>
                        </div>
                        <div className="col-lg-8">
                          <input
                            type="text"
                            name=""
                            className="searchInp cursor-pointer"
                            id=""
                            placeholder="Enter Button Name"
                            onChange={handleTextArea}
                          />
                        </div>
                      </div>
                    </div>
                    <div className="inpWrapN">
                      <div className="row">
                        <div className="col-lg-4">
                          <label>Font Family:</label>
                        </div>
                        <div className="col-lg-8">
                          {/* <Select options={font} onChange={handleFont}  */}
                          <select
                            className="searchInp cursor-pointer"
                            style={{ width: "219px" }}
                            value={selectedFont}
                            onChange={handleFont}
                          >
                            {font.map((curElem, index) => {
                              return (
                                <option
                                  key={index}
                                  value={curElem}
                                  style={{ fontFamily: curElem }}
                                >
                                  {curElem}
                                </option>
                              );
                            })}
                          </select>
                        </div>
                      </div>
                    </div>
                    <div className="inpWrapN">
                      <div className="row">
                        <div className="col-lg-4">
                          <label>Border Radius:</label>
                        </div>
                        <div className="col-lg-8">
                          <input
                            className="searchInp cursor-pointer"
                            placeholder="Enter Border radius"
                            type="number"
                            value={buttonData.borderRadius}
                            onChange={handleBorderRadius}
                          />
                        </div>
                      </div>
                    </div>
                    <div className="inpWrapN">
                      <div className="row">
                        <div className="col-lg-4">
                          <label>Border Width:</label>
                        </div>
                        <div className="col-lg-8">
                          <input
                            className="searchInp cursor-pointer"
                            type="number"
                            placeholder="Enter Border Width"
                            value={buttonData.borderWidth}
                            onChange={handleBorderWidth}
                          />
                        </div>
                      </div>
                    </div>
                    <div className="inpWrapN">
                      <div className="row">
                        <div className="col-lg-4">
                          <label>Border Color:</label>
                        </div>
                        <div className="col-lg-8">
                          <div style={{ display: "flex" }}>
                            <input
                              className="searchInp cursor-pointer"
                              type="text"
                              readOnly
                              onClick={openBox2}
                              placeholder={
                                buttonData.borderColor === ""
                                  ? "#00000"
                                  : buttonData.borderColor
                              }
                            />
                            <div className="colorBox">
                              <input
                                className="searchInp cursor-pointer"
                                style={{
                                  backgroundColor: `${buttonData.borderColor}`,
                                  width: `${"35px"}`,
                                  borderStyle: `${"solid"}`,
                                }}
                                type="text"
                                onClick={openBox2}
                                readOnly
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-sm-4">
                    <div className="btnDisp">
                      <>
                        <button
                          style={{
                            minHeight: "35px",
                            minWidth: "70px",
                            borderStyle: "solid",
                            backgroundColor: `${
                              buttonData.backgroundColor !== ""
                                ? buttonData.backgroundColor
                                : "#fffff"
                            }`,
                            color: `${
                              buttonData.textColor !== ""
                                ? buttonData.textColor
                                : "#000000"
                            }`,
                            fontFamily: `${selectedFont}`,
                            borderRadius: `${buttonData.borderRadius}px`,
                            borderWidth: `${buttonData.borderWidth}px`,
                            borderColor: `${
                              buttonData.borderColor !== ""
                                ? buttonData.borderColor
                                : "#000000"
                            }`,
                          }}
                        >
                          {buttonData.text === ""
                            ? "Button Display"
                            : buttonData.text}
                        </button>
                      </>
                    </div>
                  </div>
                </div>
              ) : (
                ""
              )}

              <div
                className="mt-5 mb-5"
                style={{ display: "flex", borderBottom: "1px solid #a82f98" }}
              >
                <h3>Upload Your Own Design</h3>
                <div
                  className="switch-single abs"
                  style={{ position: "absolute", right: "140px" }}
                >
                  <label className="switch">
                    <input
                      name="uploadBtn"
                      type="checkbox"
                      checked={toggle.fetchButton}
                      onChange={(e) => handleToggle(e)}
                    />
                    <span className="slider round"></span>
                  </label>
                </div>
              </div>
              <div>
                {toggle.fetchButton ? (
                  <div className="row">
                    <div className="col-sm-12">
                      <div className="customUp ">
                        <div className="cursor-pointer">
                          <FiUploadCloud /> <br />
                          {loader.pImageLoader ? (
                            <i className="fa fa-spinner fa-spin loadBtn " />
                          ) : (
                            "Upload Your Button"
                          )}
                        </div>
                        <input
                          type="file"
                          name="buttonImage"
                          multiple
                          onChange={(e) => onUploadBtn(e)}
                        />
                        <div></div>
                      </div>
                    </div>
                    <div className="col-sm-12">
                      <div className="buttonList ">
                        <ul>
                          {btnImage.length > 0 ? (
                            btnImage.map((elem, id) => {
                              // {console.log(elem)}
                              return (
                                <li>
                                  <div className="buttonList-single customUp cursor-pointer btnHover">
                                    <img
                                      src={elem.url}
                                      alt=""
                                      onClick={() => selectUploadBtn(elem)}
                                    />
                                  </div>

                                  <a>
                                    <FiTrash2
                                      className="smIcon cursor-pointer trash "
                                      onClick={() => handleDelete(elem.id)}
                                    />
                                  </a>
                                </li>
                              );
                            })
                          ) : (
                            <div className="customUp"> No Image</div>
                          )}
                        </ul>
                        <SweetAlert
                          warning
                          showCancel
                          confirmBtnText={button}
                          confirmBtnBsStyle="danger"
                          cancelBtnBsStyle="light"
                          title="Are you sure?"
                          onConfirm={(e) => onConfirm(e)}
                          onCancel={(e) => onCancel(e)}
                          focusCancelBtn
                          show={showDelete}
                        ></SweetAlert>
                      </div>
                    </div>
                  </div>
                ) : (
                  ""
                )}
              </div>
            </div>
            <div className="generateBtn">
              <button
                className="fbtn demoLink m-0"
                style={{ float: "right" }}
                onClick={() => generateBtn()}
              >
                Generate Button
              </button>
            </div>
          </div>
        </div>
      </section>
      <div className="copyCode">
        <div className="container">
          <div className="inpWrapN m-0">
            <div className="row align-items-center">
              <div className="col-lg-2">
                <label>Button Code</label>
              </div>
              <div className="col-lg-10">
                <div className="inpWithBtn">
                  <input className="inpWrapN-inp" type="text" />
                  <button className="fbtn btn-block demoLink m-0">Copy</button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <Footer />
    </>
  );
};

export default ButtonCode;

export const addBgColor = (data) => (dispatch) => {
  dispatch({ type: "ADD_BG_COLOR", payload: data });
};

export const addTextColor = (data) => (dispatch) => {
  dispatch({ type: "ADD_TEXT_COLOR", payload: data });
};

export const addText = (data) => (dispatch) => {
  dispatch({ type: "ADD_TEXT", payload: data });
};
export const addFontFamily = (data) => (dispatch) => {
  dispatch({ type: "ADD_FONT_FAMILY", payload: data });
};

export const addBorderRadius = (data) => (dispatch) => {
  dispatch({ type: "ADD_BORDER_RADIUS", payload: data });
};

export const addBorderWidth = (data) => (dispatch) => {
  dispatch({ type: "ADD_BORDER_WIDTH", payload: data });
};

export const addBorderColor = (data) => (dispatch) => {
  dispatch({ type: "ADD_BORDER_COLOR", payload: data });
};

export const setImage = (data) => (dispatch) => {
  dispatch({ type: "SET_IMAGE", payload: data });
};
